import React, {useEffect, useState} from 'react'
import {withI18N} from "../hoc-components";
import compose from "../../utils/helper-functions/compose";
import Loader from "../helper-components/loader/loader";
import {config} from "../../config/config";
import {getCustomerData} from "../../state/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createFatalError} from "../../state/actions/errors-actions";
import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs.ts";

const ProcessingCustomerData = (props) => {
    const { children, langBack, getCustomStyles } = props;
    const [customCssLoaded, setCustomCssLoaded] = useState(false);
    const location = useSelector(({location}) => location, shallowEqual);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(true);

    const customCss = getCustomStyles();

    useEffect(() => {
        const isCallback = defineIsCallback();
        const callback = isCallback ? 1 : 0;
        dispatch(getCustomerData(callback))
            .then((data) => {
                processingData(data).then(() => setLoader(false)).catch(() => setLoader(false))
            })
    }, [getCustomerData, location]);

    const handleLoadingOfCustomCss = () => setCustomCssLoaded(true);

    useEffect(() => {
        document.body.addEventListener('NEW_STYLES_ADDED', handleLoadingOfCustomCss);

        return () => {
            document.body.removeEventListener('NEW_STYLES_ADDED', handleLoadingOfCustomCss);
        };
    }, [])

    const defineIsCallback = () => {
        try {
            const url = new URL(location?.href);
            const queryKeys = Array.from(url?.searchParams?.keys());
            return queryKeys?.includes('callback');
        } catch (err) {
            const errorText = "Cant parse url";

            sendOpenSearchLogs({
                event_group: "processing_customer_data",
                error_data: {
                    error: {
                        message: errorText,
                        stack: err
                    }
                }
            });

            console.error(errorText, err);
            return false;
        }
    }

    const processingData = async(profile={}) => {
        const {customer:{country, balance}={}, base_amount, frontend_name:name, session_type, transaction_type:type} = profile;

        if (session_type === 'vt') {
            document.title = `Terminal | ${name? name : ""}`;
        } else if (session_type === 'cashier') {
            document.title = `${config.title} | ${name? name : ""}`;
        }

        if (type === "pay-out" && base_amount && balance && +base_amount > +balance) {
            dispatch(createFatalError("Please try again with lower amount."))
        }

        if (country) {
            document.documentElement.setAttribute("data-country", country);
        }

    };

    if(loader) return <>
        {customCss && customCssLoaded ? <Loader/> : <Loader text={langBack('initialLoadingText', false, true)} showLoaderIcon={false}/>}
    </>;

    return children
};

export default compose(
    withI18N
)(ProcessingCustomerData)
