import React, { useEffect, useRef, useState } from "react";
import InputWrapper from "../input-wrapper/input-wrapper";
import { containsNonLatinLetters } from "../../../utils/helper-functions/sanitize-non-latin-characters";
import { additionalFieldsData } from "variables/additional-fields-data";
import { useSelector } from "react-redux";

const CreateInputText = (props) => {
  const {
    input,
    errors,
    onChange,
    onFieldLoad,
    onBlur,
    onFocus,
    fields,
    required = [],
    template = {},
    cashier_theme,
    icon,
    langBack,
    s,
    onClick,
    isPixV2,
  } = props;

  const {
    name,
    label,
    default_value = "",
    is_mutable,
    type,
    is_readonly,
  } = input;

  const isEditable =
    input?.is_editable !== undefined && input.is_editable === 1;
  const isNotEditable =
    input?.is_editable !== undefined && input.is_editable === 0;

  const [inputValue, setInputValue] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const inputEl = useRef(null);

  const payData = useSelector((state) => state.payData);
  const payInfo = useSelector((state) => state.payInfo);
  const isTemplate = payData?.template_token;

  const getInitialPixV2FieldValue = () => {
    let savedValue = default_value || template[name] || "";
    const cachedLength = Object.keys(
      payInfo?.inputEditorCachedValue || {},
    ).length;

    if (!savedValue && default_value && cachedLength) {
      savedValue = default_value;
    }

    const isMasked = savedValue?.includes("*");
    const isPreviouslySavedValue =
      savedValue?.length > 0 && fields[name] === savedValue;
    const { is_account_identifier } = input;

    const isSensitiveData =
      type === "email" ||
      type === "phone" ||
      type === "cpf" ||
      type === "cnpj" ||
      type === "cpf_cnpj";

    if (isEditable || isNotEditable) {
      if (input?.is_masked !== undefined && input.is_masked === 0) {
        return fields[name];
      }
    }

    return isPreviouslySavedValue &&
      !isMasked &&
      isSensitiveData &&
      is_account_identifier === 0
      ? additionalFieldsData[type]?.maskFunction(savedValue)
      : fields[name];
  };

  useEffect(() => {
    const valueToSet = isPixV2
      ? getInitialPixV2FieldValue()
      : (fields[name] ?? template[name] ?? default_value);

    const hasInitialValue =
      (template[name] && template[name].length > 0) ||
      (default_value && default_value?.length > 0);

    const containsNonLatin = containsNonLatinLetters(valueToSet);
    const shouldBeReadOnly =
      is_readonly === 1 && default_value && default_value?.length > 0;

    if (containsNonLatin) {
      console.warn(`Initial value for ${name} contains non-Latin letters.`);
      setInputValue("");
      setReadOnly(false);
    } else {
      if (shouldBeReadOnly) {
        setReadOnly(true);
        setInputValue(isPixV2 ? getInitialPixV2FieldValue() : default_value);
      } else {
        if (isEditable || isNotEditable) {
          if (isEditable) {
            setInputValue(valueToSet);
            setReadOnly(false);
            return;
          }

          if (isNotEditable) {
            if (isTemplate) setInputValue(valueToSet);
            else setInputValue(fields[name]);
          }
        }

        switch (is_mutable) {
          case "keep_editable_if_empty":
            setInputValue(valueToSet);
            setReadOnly(hasInitialValue);
            break;
          case "keep_editable":
            setReadOnly(false);
            setInputValue(valueToSet);
            break;
          case "keep_empty":
            setReadOnly(false);
            if (isFirst) {
              setInputValue(
                isPixV2 ? getInitialPixV2FieldValue() : default_value,
              );
              setIsFirst(false);
            } else {
              setInputValue(valueToSet);
            }
            break;
          default:
            setReadOnly(false);
            setInputValue(valueToSet);
            break;
        }
      }
    }
  }, [default_value, fields, name, template, is_mutable, is_readonly, isFirst]);

  const handleInputChange = (event) => {
    if (!event || !event.target) return;
    let { value, name } = event.target;
    let sanitizedValue = value;
    if (containsNonLatinLetters(value)) {
      // Regex: Allows only basic Latin letters, digits, and common punctuation
      // and removes characters that are not part of these sets
      sanitizedValue = sanitizedValue.replace(/[^\u0000-\u007F]+/g, "");
    }

    if (!readOnly) {
      sanitizedValue = sanitizedValue.replace(/[\r\n]/g, "").trimStart();
    }

    // Remove spaces for email fields
    if (name === "email") {
      sanitizedValue = sanitizedValue.replace(/\s/g, "");
    }

    // Prevent unnecessary updates that might override spaces
    if (sanitizedValue !== value) {
      event.target.value = sanitizedValue;
    }

    // Update the state with the sanitized value
    setInputValue(sanitizedValue);
  };
  const localOnChange = (e) => {
    handleInputChange(e);
    onChange(e);
  };

  const getError = () => {
    if (required.includes(name) && errors[name]) {
      return errors[name];
    }

    // If the field is not required and it's empty, don't show an error
    if (!required.includes(name) && fields[name] === "") {
      return null;
    }

    return errors[name] ? errors[name] : null;
  };

  const getPlaceholder = () => {
    if (input?.placeholder) {
      return langBack(input.placeholder);
    } else if (cashier_theme !== "power-slide") {
      return langBack(label);
    } else {
      return "";
    }
  };

  return (
    <InputWrapper
      title={langBack(label, true)}
      error={getError()}
      name={name}
      icon={
        icon?.props
          ? {
              ...icon,
              props: { ...icon.props, handleInputChange: setInputValue },
            }
          : icon
      }
    >
      <input
        onClick={() =>
          typeof onClick === "function" && !readOnly ? onClick() : false
        }
        className={`${s("input")} ${readOnly ? s("read-only") : ""}`}
        value={inputValue}
        name={name}
        required={required.includes(name)}
        readOnly={readOnly}
        disabled={readOnly}
        placeholder={getPlaceholder()}
        onBlur={onBlur}
        onFocus={() => onFocus(name)}
        onChange={localOnChange}
        type="text"
        ref={inputEl}
        data-additional-field-type={type}
      />
    </InputWrapper>
  );
};

export default CreateInputText;
